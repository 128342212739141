<template>
	<div class="mhome">
		<div class="mhome-wrap">
			<div class="mstore-section mstore-head">
				<router-link :to="{name:'mStoreInfo',params:{id:$route.params.id}}" class="head-info">
					<img :src="storeInfo.store_logo ? storeInfo.store_logo : $imageErrorLogo" @error="imageShopLoadError" class="pic">
					<div class="info-box">
						<div class="name">{{storeInfo.store_name}}</div>
						<div class="text">{{$t('mobile.tags.fans')+':'}}{{storeInfo.count_fans || 0}}</div>
					</div>
				</router-link>
				<div class="follow" @click="handleUnFollow" v-if="storeInfo.is_follow==1">{{$t('mobile.actions.unfollow')}}</div>
				<div class="follow" @click="handleFollow" v-else>{{'+'+$t('mobile.actions.follow')}}</div>
			</div>
			<div class="mstore-section mstore-evaluate">
				<div class="item">
					<div class="item-label">{{$t('mobile.shop.text1')}}</div>
					<div class="item-box"><el-progress :percentage="96" :stroke-width="12" :show-text="false"></el-progress></div>
					<div class="item-value">4.8</div>
					<div class="item-text">{{$t('mobile.shop.text4')}}</div>
				</div>
				<div class="item">
					<div class="item-label">{{$t('mobile.shop.text2')}}</div>
					<div class="item-box"><el-progress :percentage="96" :stroke-width="12" :show-text="false"></el-progress></div>
					<div class="item-value">4.8</div>
					<div class="item-text">{{$t('mobile.shop.text4')}}</div>
				</div>
				<div class="item">
					<div class="item-label">{{$t('mobile.shop.text3')}}</div>
					<div class="item-box"><el-progress :percentage="96" :stroke-width="12" :show-text="false"></el-progress></div>
					<div class="item-value">4.8</div>
					<div class="item-text">{{$t('mobile.shop.text4')}}</div>
				</div>
			</div>
			<div class="mstore-section" v-if="impressionInfo.introduce && impressionInfo.introduce.is_off==1">
				<div class="section-title">{{$t('mobile.shop.text5')}}</div>
				<div class="mstore-introduce">
					<video :src="impressionInfo.introduce.thumb" controls="controls"></video>
				</div>
			</div>
			<div class="mstore-section mstore-story" v-if="impressionInfo.story && impressionInfo.story.is_off==1">
				<div class="section-title">{{$t('mobile.shop.text6')}}</div>
				<div class="item" v-for="(item,index) in impressionInfo.story.thumb" :key="index">
					<video :src="item.path" controls="controls"></video>
					<a class="item-box" :href="item.link ? item.link : 'javascript:void(0);'">
						<div class="name">{{item.name}}</div>
						<div class="btn">{{$t('mobile.shop.text7')}}</div>
					</a>
				</div>
			</div>
			<div class="mstore-section mstore-explain" v-if="impressionInfo.explain && impressionInfo.explain.is_off==1">
				<div class="section-title">{{$t('mobile.shop.text8')}}</div>
				<a class="item" v-for="(item,index) in impressionInfo.explain.thumb" :key="index" :href="item.link ? item.link : 'javascript:void(0);'">
					<div class="item-label">{{item.name}}</div>
					<div class="item-block"><i class="el-icon-arrow-right"></i></div>
				</a>
			</div>
			<div class="mstore-section mstore-info">
				<div class="section-title">{{$t('mobile.shop.text9')}}</div>
				<div class="item">
					<div class="item-label">{{$t('mobile.shop.text10')}}</div>
					<div class="item-block"><span>{{storeInfo.store_name}}</span></div>
				</div>
				<div class="item">
					<div class="item-label">{{$t('mobile.shop.text11')}}</div>
					<div class="item-block">
						<img class="icon" src="@/assets/images/seller/decorate-steps-32-32.png" alt="">
						<span>{{$t('mobile.shop.text12')}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import { getToken } from '@/utils/auth';
	import { fetchShopInfo, fetchIntroduction, onCollect, onUncollect, browseStore } from '@/api/mobile/store';
	export default {
		data() {
			return {
				storeInfo: {},
				impressionInfo: {}
			}
		},
		mounted() {
			this.getStoreInfo();
		},
		methods: {
			imageShopLoadError(e){
				e.target.src = this.$imageErrorLogo;
				e.target.style.objectFit = 'fill';
			},
			async getStoreInfo() {
				try {
					const params = {
						store_id: this.$route.params.id
					}
					const info = await fetchShopInfo(params)
					this.storeInfo = info.data

					await browseStore({...params, from: 4 })

					const impression = await fetchIntroduction(params)
					this.impressionInfo = impression.data ? impression.data.content : {}

				} catch (error) {
					this.$router.go(-1)
				}
			},
			handleFollow(){
				const hasToken = getToken()
				if(!hasToken){
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning',
						duration: 1500,
						onClose:()=>{
							window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
						}
					});
					return false;
				}
				let param = {
					type: 'store',
					followId: this.storeInfo.store_id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				onCollect(param).then(() => {
					this.$message({
						message: this.$t('mobile.successMsg.follow'),
						customClass: 'wap-message',
						type: 'success'
					});
					this.getStoreInfo();
				})
			},
			handleUnFollow(){
				const hasToken = getToken()
				if(!hasToken){
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning',
						duration: 1500,
						onClose:()=>{
							window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
						}
					});
					return false;
				}
				let param = {
					type: 'store',
					followId: this.storeInfo.store_id,
					uuid: this.$store.getters.uuid,
					app_key: this.$store.getters.appKey
				}
				onUncollect(param).then(() => {
					this.$message({
						message: this.$t('mobile.cancelMsg.follow'),
						customClass: 'wap-message',
						type: 'warning'
					});
					this.getStoreInfo();
				})
			}
		}
	}
</script>
<style lang="scss" scoped>
	.mstore-section {
		padding: 0 0.384rem;
		margin-bottom: 0.32rem;
		.section-title {
			color: #999999;
			font-size: 0.512rem;
			padding: 0.32rem 0;
			height: 0.64rem;
			line-height: 0.64rem;
		}
	}
	.mstore-head {
		padding: 0.384rem;
		position: relative;
		background-color: #FFFFFF;
		.head-info {
			display: flex;
			.pic {
				width: 1.92rem;
				height: 1.92rem;
				object-fit: cover;
				border-radius: 50%;
				margin-right: 0.384rem;
			}
			.info-box {
				display: flex;
				flex-flow: column;
				justify-content: space-between;
				.name {
					color: #333333;
					font-size: 0.448rem;
					height: 0.64rem;
					line-height: 0.64rem;
				}
				.text {
					color: #999999;
					font-size: 0.384rem;
					height: 0.512rem;
					line-height: 0.512rem;
				}
			}
		}
		.follow {
			right: 0.384rem;
			bottom: 0.384rem;
			position: absolute;
			height: 0.768rem;
			line-height: 0.768rem;
			text-align: center;
			font-size: 0.384rem;
			width: 1.92rem;
			border-radius: 0.384rem;
			color: #FFFFFF;
			background: linear-gradient(to right, #ff9900, #ff560a);
		}
	}
	.mstore-evaluate {
		background-color: #FFFFFF;
		.item {
			height: 1.28rem;
			display: flex;
			align-items: center;
			div {
				margin-right: 0.48rem;
			}
			.item-label {
				font-size: 0.448rem;
				color: #444444;
			}
			.item-box {
				width: 3.904rem;
				::v-deep .el-progress {
					.el-progress-bar__inner {
						background: linear-gradient(90deg,#f90,#ff560a);
					}
				}
			}
			.item-value {
				color: #ff5000;
				font-weight: bold;
				font-size: 0.448rem;
			}
			.item-text {
				color: #ff5000;
				font-size: 0.32rem;
			}
		}
	}
	.mstore-introduce {
		border-radius: 0.32rem;
		overflow: hidden;
		video {
			width: 100%;
			height: 6.4rem;
			background-color: #222222;
		}
	}
	.mstore-story {
		.item {
			border-radius: 0.32rem;
			overflow: hidden;
			background-color: #FFFFFF;
			margin-bottom: 0.32rem;
			video {
				width: 100%;
				height: 6.4rem;
				background-color: #222222;
			}
			.item-box {
				padding: 0.384rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				.name {
					flex: 1;
					width: 0;
					font-size: 0.416rem;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.btn {
					width: 2.048rem;
					height: 0.768rem;
					line-height: 0.768rem;
					border-radius: 0.384rem;
					font-size: 0.352rem;
					color: #FFFFFF;
					text-align: center;
					margin-left: 0.32rem;
					background: linear-gradient(to right, #ff9900, #ff560a);
				}
			}
		}
	}
	.mstore-explain {
		background-color: #FFFFFF;
		.item {
			height: 1.28rem;
			display: flex;
			color: #333333;
			align-items: center;
			justify-content: space-between;
			.item-label {
				width: 0;
				flex: 1;
				font-size: 0.448rem;
			}
			.item-block {
				color: #777777;
				font-size: 0.448rem;
			}
		}
	}
	.mstore-info {
		background-color: #FFFFFF;
		.item {
			height: 1.28rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.item-label {
				font-size: 0.448rem;
			}
			.item-block {
				font-size: 0.384rem;
				display: flex;
				align-items: center;
				.icon {
					width: 0.64rem;
					height: 0.64rem;
					margin-right: 0.192rem;
				}
			}
		}
	}
</style>